import Amplitude from 'amplitude-js';
import { getLanguage, t } from 'react-switch-lang';
import { isDevelopment } from './HostingEnv';

const AMPLITUDE_SESSION_TIMEOUT = 20 * 60 * 1000;
const ampLogStyle = 'background-color:aqua; color:black;';
let testMode = false;
let userId;
let Platform;
if (document.referrer === 'https://in.xero.com/') {
  Platform = 'Xero';
} else if (document.referrer.endsWith('myshopify.com/')) {
  Platform = 'Shopify';
} else {
  Platform = new URLSearchParams(window.location.search).get('platform') || document.referrer || 'Unknown';
}

const log = isDevelopment ? (...args) => {
  // eslint-disable-next-line no-console
  console.log(...args);
} : () => {};

export const initAmplitude = (isTestMode) => {
  testMode = isTestMode;
  Amplitude.getInstance().init(process.env.REACT_APP_AMPLITUDE_KEY, null, {
    includeReferrer: true,
    includeUtm: true,
    includeGclid: true,
    sessionTimeout: AMPLITUDE_SESSION_TIMEOUT,
    secureCookie: true,
  });
  Amplitude.getInstance().setVersionName(process.env.REACT_APP_VERSION);
};

export const getAmpSessionID = () => Amplitude.getInstance().getSessionId();
export const setAmpUser = (id) => {
  log(`%c[Amplitude]%c Setting user ID: ${id}`, ampLogStyle, '');
  userId = id;
  Amplitude.getInstance().setUserId(id);
};
export const getAmpUser = () => userId;
export const setUserProps = (properties) => {
  log('%c[Amplitude]%c Setting user property:', ampLogStyle, '', properties);
  Amplitude.getInstance().setUserProperties(properties);
};
export const setPlatform = (platform) => {
  Platform = platform;
};
export const getPlatform = () => Platform;

let partnerName;
export const setPartnerName = (searchParam) => {
  partnerName = {
    en: searchParam.get('pne'),
    fr: searchParam.get('pnf'),
  };
};
export const getPartnerName = (lang = undefined) => {
  if (!lang) lang = getLanguage(); // eslint-disable-line no-param-reassign
  if (!partnerName) setPartnerName(new URLSearchParams(window.location.search));
  return partnerName[lang] || t('PartnerNameDefault', null, lang);
};

export const events = {
  // available
  USER_VIEWED_ARRIVAL_PAGE: 'USER_VIEWED_ARRIVAL_PAGE',
  USER_CLICKED_PAY_NOW: 'USER_CLICKED_PAY_NOW',
  USER_CLICKED_LIMITS_HELP_LINK: 'USER_CLICKED_LIMITS_HELP_LINK',

  // initiated
  USER_VIEWED_WAITING_FOR_PAYMENT_PAGE: 'USER_VIEWED_WAITING_FOR_PAYMENT_PAGE',
  USER_VIEWED_PROCESSING_PAYMENT_PAGE: 'USER_VIEWED_PROCESSING_PAYMENT_PAGE',
  USER_CLICKED_OPEN_INTERAC_LINK_AGAIN: 'USER_CLICKED_OPEN_INTERAC_LINK_AGAIN',
  USER_CLICKED_CHANGE_PAYMENT_EXPERIENCE_TYPE: 'USER_CLICKED_CHANGE_PAYMENT_EXPERIENCE_TYPE',
  PAYMENT_RECEIVED: 'PAYMENT_RECEIVED',
  USER_OPENED_PAYMENT_INSTRUCTIONS: 'USER_OPENED_PAYMENT_INSTRUCTIONS',
  USER_CLOSED_PAYMENT_INSTRUCTIONS: 'USER_CLOSED_PAYMENT_INSTRUCTIONS',
  USER_BLURRED_FROM_WINDOW: 'USER_BLURRED_FROM_WINDOW',
  USER_RETURNED_TO_WINDOW: 'USER_RETURNED_TO_WINDOW',

  // deposited
  USER_VIEWED_PAYMENT_RECEIVED_PAGE: 'USER_VIEWED_PAYMENT_RECEIVED_PAGE',
  REDIRECTING_BACK_TO_STORE: 'REDIRECTING_BACK_TO_STORE',
  // expired, cancelled
  USER_VIEWED_PAYMENT_STATUS_PAGE: 'USER_VIEWED_PAYMENT_STATUS_PAGE',
  USER_CLICKED_FUNDS_HELD_HELP_LINK: 'USER_CLICKED_FUNDS_HELD_HELP_LINK',

  // fake bank
  USER_VIEWED_FAKE_INTERAC_PAGE: 'USER_VIEWED_FAKE_INTERAC_PAGE',
  USER_SELECTED_FAKE_BANK: 'USER_SELECTED_FAKE_BANK',
  USER_VIEWED_FAKE_BANK_PAGE: 'USER_VIEWED_FAKE_BANK_PAGE',
  USER_CLICKED_FAKE_BANK_ACCEPT_RFM: 'USER_CLICKED_FAKE_BANK_ACCEPT_RFM',

  // footer
  USER_CLICKED_FOOTER_LINK: 'USER_CLICKED_FOOTER_LINK',
  USER_CHANGED_LANGUAGE: 'USER_CHANGED_LANGUAGE',

  // general
  API_ERROR: 'API_ERROR',
  API_SUCCESS: 'API_SUCCESS',
  JWT_ERROR: 'JWT_ERROR',
  USER_CLICKED_CANCEL_PAYMENT: 'USER_CLICKED_CANCEL_PAYMENT',
  USER_VIEWED_ERROR_PAGE: 'USER_VIEWED_ERROR_PAGE',
  USER_VIEWED_404_ERROR_PAGE: 'USER_VIEWED_404_ERROR_PAGE',
  USER_VIEWED_JWT_EXPIRED_PAGE: 'USER_VIEWED_JWT_EXPIRED_PAGE',
  USER_CLICKED_RETURN_TO_STORE: 'USER_CLICKED_RETURN_TO_STORE',
  NO_PARTNER_NAME_PROVIDED: 'NO_PARTNER_NAME_PROVIDED',
  USER_CLICKED_ANIMATION_BUTTON: 'USER_CLICKED_ANIMATION_BUTTON',
};
const eventsWithRefAsEventProp = [
  events.USER_VIEWED_ERROR_PAGE,
  events.USER_VIEWED_404_ERROR_PAGE,
];

export const logAmpEvent = (event, properties = undefined) => {
  const props = {
    ...properties,
    'Test Mode': testMode,
    'English Partner Name': partnerName?.en || '(empty)',
    'French Partner Name': partnerName?.fr || '(empty)',
    Platform,
  };
  if (eventsWithRefAsEventProp.includes(event)) {
    const ref = new URLSearchParams(window.location.search).get('ref');
    if (ref) props.Reference = ref; // this is APICALL_Seq
  }
  log(`Amplitude event: %c${event}`, ampLogStyle, 'with properties:', props);
  Amplitude.getInstance().logEvent(event, props);
};
