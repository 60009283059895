import { useEffect } from 'react';
import { t, translate } from 'react-switch-lang';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle } from '@fortawesome/pro-regular-svg-icons';

import Layout from './Layout';
import { events, getPartnerName, logAmpEvent } from '../utils/Amplitude';
import { setPageTitle } from '../utils/PageTitle';
import './ErrorPage.scss';
import { ItalicizeInterac } from './ReplaceTextWithElement';

function ErrorPage({
  amount = null,
  titleKey = 'ErrorPage_Title',
  errorMsg = null,
  returnUrl = null,
  ampEvent = events.USER_VIEWED_ERROR_PAGE,
  ampProp = undefined,
}) {
  return (
    <Layout amount={amount} pageTitle={t('PageTitle_Error')}>
      <div id="content">
        <ErrorContent
          titleKey={titleKey}
          errorMsg={errorMsg}
          returnUrl={returnUrl}
          ampEvent={ampEvent}
          ampProp={ampProp}
        />
      </div>
    </Layout>
  );
}

export function ErrorContent({
  titleKey = 'ErrorPage_Title',
  pageTitleKey = 'PageTitle_Error',
  errorMsg = null,
  returnUrl = null,
  ampEvent = events.USER_VIEWED_ERROR_PAGE,
  ampProp = undefined,
  children,
  lang = undefined,
}) {
  useEffect(() => {
    let prop = ampProp;
    if (ampEvent === events.USER_VIEWED_ERROR_PAGE) {
      prop = { 'Displayed Message': errorMsg || t('ErrorPage_Body', { partner: getPartnerName() }, 'en'), ...ampProp };
    }
    if (ampEvent) logAmpEvent(ampEvent, prop);
    if (pageTitleKey) setPageTitle(t(pageTitleKey));
  }, [errorMsg, ampEvent, ampProp, pageTitleKey]);
  return (
    <div className="errorContent" lang={lang}>
      <h1>
        <FontAwesomeIcon icon={faExclamationCircle} />
        {t(titleKey, null, lang)}
      </h1>
      {children || <p><ItalicizeInterac text={errorMsg || t('ErrorPage_Body', { partner: getPartnerName() })} /></p>}
      {returnUrl && <a id="ReturnToStore" href={returnUrl} className="btn" onClick={() => logAmpEvent(events.USER_CLICKED_RETURN_TO_STORE)}>{t('Btn_ReturnToStore', { partner: getPartnerName(lang) }, lang)}</a>}
    </div>
  );
}

export default translate(ErrorPage);
