import React from 'react';

/**
 * Replaces instances of replaceKey with replaceWith in text.
 * @param {object} props
 * @param {string} props.text text to be converted
 * @param {string} props.replaceKey pattern to be replaced
 * @param {React.ReactNode} props.replaceWith ReactNode to replace the replaceKey with
 * @param {number} [props.nthOnly] only replace the nth occurence (starts with 1 not 0)
 * @returns {string | React.ReactNode[]} original text if not found, else array of ReactNodes
 */
export default function ReplaceTextWithElement({ text, replaceKey, replaceWith, nthOnly }) {
  if (!text.includes(replaceKey)) return text;

  return text.split(replaceKey).map((e, i) => {
    if (i === 0) return e;
    return (
      <React.Fragment key={e}>
        {(nthOnly && i !== nthOnly) ? replaceKey : replaceWith}
        {e}
      </React.Fragment>
    );
  });
}

export function ItalicizeInterac({ text }) {
  return <ReplaceTextWithElement text={text} replaceKey="Interac" replaceWith={<i>Interac</i>} />;
}
