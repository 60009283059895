import { isDevelopment } from './HostingEnv';

export const moveFocusTo = (id, scrollTop = false, preventScroll = undefined) => {
  const el = document.getElementById(id);
  if (el) {
    el.setAttribute('tabIndex', -1);
    el.focus({ preventScroll });
    if (scrollTop) window.scrollTo(0, 0);
  }
};

export function scrollToTop(focusID = 'focus-helper') {
  window.scrollTo(0, 0);
  moveFocusTo(focusID);
}

/**
 * Creates an element with a specified role and appends it to an existing element.
 * These alerts are used by screen readers to notify users something has happened.
 *
 * role = 'alert' - Used for negative announcements (errors, warnings, etc)
 * role = 'status' - Used for positive announcements (successful form submission, etc)
 *
 * @param {any} msg
 * The message that will be read aloud.
 * @param {any} appendTo
 * Where the element will be attached to.
 * @param {any} role
 * Role type (alert or status preferably)
 */
export const setScreenReaderAlert = (
  msg,
  role = 'alert',
  appendTo = document.body
) => {
  if (!msg) return;

  const screenreaderAlert = document.createElement('span');
  screenreaderAlert.className = 'visually-hidden';
  screenreaderAlert.setAttribute('role', role);
  const txtNode = document.createTextNode(msg);
  screenreaderAlert.appendChild(txtNode);
  appendTo.append(screenreaderAlert);

  if (isDevelopment) {
    // eslint-disable-next-line no-console
    console.log(`Role with ${role} saying ${msg}`);
  }
};
