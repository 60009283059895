import React from 'react';
import { getLanguage, setLanguage, t, translate } from 'react-switch-lang';

import { logAmpEvent, events, getPartnerName } from '../utils/Amplitude';
import './Footer.scss';
import Link from './Link';
import ReplaceTextWithElement, { ItalicizeInterac } from './ReplaceTextWithElement';

function Footer() {
  const newLang = getLanguage() === 'fr' ? 'en' : 'fr';
  return (
    <footer>
      <p>
        <ReplaceTextWithElement
          text={t('HowItWorks_Info3', { partner: getPartnerName() })}
          replaceKey="Payment Source"
          replaceWith={(
            <Link
              href={t('Link_PaymentSource')}
              ampEvent={events.USER_CLICKED_FOOTER_LINK}
              ampEventProps={{ Link: 'Payment Source' }}
              text="Payment Source"
              aria-label={t('LinkDescription_PaymentSource')}
            />
          )}
          nthOnly={1}
        />
      </p>
      <div>
        <div id="links">
          <span>
            <Link
              href={t('Link_AccessibilityStatement')}
              ampEvent={events.USER_CLICKED_FOOTER_LINK}
              ampEventProps={{ Link: 'Accessibility' }}
              text={t('Footer_Lbl_Accessibility')}
            />
          </span>
          <span>
            <Link
              href={t('Link_PSI_Privacy')}
              ampEvent={events.USER_CLICKED_FOOTER_LINK}
              ampEventProps={{ Link: 'Privacy Policy' }}
              text={t('Footer_Lbl_Privacy')}
            />
          </span>
          <span>
            <button
              type="button"
              aria-label={t('ButtonDescription_SwitchLanguage', null, newLang)}
              lang={newLang}
              onMouseDown={(e) => e.preventDefault()} // prevent button from staying focused
              onClick={() => {
                logAmpEvent(events.USER_CHANGED_LANGUAGE, { Language: newLang === 'en' ? 'English' : 'French' });
                setLanguage(newLang);
                document.documentElement.setAttribute('lang', newLang); // set <html> lang attr
                const searchParams = new URLSearchParams(window.location.search);
                searchParams.set('lang', newLang);
                const newRelativePathQuery = `${window.location.pathname}?${searchParams.toString()}${window.location.hash}`;
                history.replaceState(null, '', newRelativePathQuery); // eslint-disable-line no-restricted-globals
              }}
            >
              {newLang === 'en' ? 'English' : 'Français'}
            </button>
          </span>
        </div>
        <div id="disclaimer">
          <p><ItalicizeInterac text={t('Footer_Disclaimer')} /></p>
        </div>
      </div>
    </footer>
  );
}

export default translate(Footer);
