import { translate } from 'react-switch-lang';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinnerThird } from '@fortawesome/pro-duotone-svg-icons';
import { faPlay, faPause } from '@fortawesome/pro-solid-svg-icons';
import './IconTextContainer.scss';
import { useState } from 'react';
import { events, logAmpEvent } from '../utils/Amplitude';
import { setScreenReaderAlert } from '../utils/Accessibility';

/**
 * @param {object} props
 * @param {boolean} props.withSpinner
 * @param {import('@fortawesome/fontawesome-svg-core').IconProp} props.icon
 * @param {string} props.title key in lang file
 */
function IconTextContainer({ t, withSpinner, icon, title, children, ...props }) {
  const [paused, setPaused] = useState(false);
  return (
    <div {...props} className={`iconTextCont ${props.className || ''}`}>
      {withSpinner ? (
        <>
          <div className="fa-stack">
            <FontAwesomeIcon icon={faSpinnerThird} spin={!paused} className="fa-stack-2x" />
            <FontAwesomeIcon icon={icon} className="fa-stack-1x" />
          </div>
          <button
            type="button"
            title={paused ? t('ResumeAnimation_ButtonDescription') : t('PauseAnimation_ButtonDescription')}
            onClick={() => {
              logAmpEvent(
                events.USER_CLICKED_ANIMATION_BUTTON,
                { Action: paused ? 'Resumed' : 'Paused' }
              );
              setScreenReaderAlert(t(paused ? 'ScreenReaderAlert_AnimationResumed' : 'ScreenReaderAlert_AnimationPaused'));
              setPaused((prev) => !prev);
            }}
          >
            <FontAwesomeIcon icon={paused ? faPlay : faPause} size="lg" fixedWidth />
          </button>
        </>
      ) : (
        <FontAwesomeIcon icon={icon} size="6x" />
      )}
      <h1>{t(title)}</h1>
      {children}
    </div>
  );
}

export default translate(IconTextContainer);
