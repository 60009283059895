import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { t, translate } from 'react-switch-lang';
import { isMobile } from 'react-device-detect';
import { faEnvelopeOpenDollar } from '@fortawesome/pro-solid-svg-icons';

import IconTextContainer from '../IconTextContainer';
import { PAYMENT_STATUS } from '../../utils/Constants';
import { ERROR, queryPayment } from '../../utils/ApiClient';
import { events, getPartnerName, logAmpEvent } from '../../utils/Amplitude';
import { setPageTitle } from '../../utils/PageTitle';
import PartnerPayContext from '../../PartnerPayContext';
import './PaymentInitiated.scss';
import { useWindowFocusStateListener } from '../../utils/Helpers';
import { ItalicizeInterac } from '../ReplaceTextWithElement';

const pollingInterval = 8000;
const delayTimeout = 5 * 60 * 1000; // 5 minutes

function PaymentProcessing({ setStatus }) {
  const { jwt, seq, exp, testMode } = useContext(PartnerPayContext);
  const [delayDetected, setDelayDetected] = useState(false);
  if (process.env.REACT_APP_ENV === 'test' && window.debug) window.setDelayed = setDelayDetected;
  const [isWindowFocusedRef, setOnFocusCallback] = useWindowFocusStateListener();
  const fetchFailureCountRef = useRef(0);

  const query = useCallback(async () => {
    // for mobile, only make query calls when focused on the page
    if (isMobile && !isWindowFocusedRef.current) return;
    const resp = await queryPayment(jwt, seq, exp, true);
    if (resp.Status === ERROR && resp.ErrorName === 'TypeError') {
      fetchFailureCountRef.current += 1;
      if (fetchFailureCountRef.current < 3) return; // retry until third fetch failure
    } else {
      fetchFailureCountRef.current = 0;
    }

    if (testMode && resp.Status === PAYMENT_STATUS.AVAILABLE) return;
    if (resp.Status === PAYMENT_STATUS.DEPOSITED) logAmpEvent(events.PAYMENT_RECEIVED);
    setStatus(resp.Status);
  }, [exp, jwt, seq, setStatus, testMode, isWindowFocusedRef]);

  useEffect(() => {
    logAmpEvent(events.USER_VIEWED_PROCESSING_PAYMENT_PAGE);
    setPageTitle(t('PageTitle_PaymentProcessing'));
    const id = setTimeout(() => setDelayDetected(true), delayTimeout);

    return () => clearTimeout(id);
  }, []);

  useEffect(() => {
    const pollingId = setInterval(query, pollingInterval);

    return () => clearInterval(pollingId);
  }, [query]);

  useEffect(() => {
    // query immediately on focus for mobile
    if (!isMobile) return;
    setOnFocusCallback(() => query);
  }, [query, setOnFocusCallback]);

  return (
    <IconTextContainer id="Processing" icon={faEnvelopeOpenDollar} title={t('PaymentProcessing_Title')} withSpinner>
      <p><ItalicizeInterac text={t('PaymentProcessing_Body')} /></p>
      {delayDetected && (
        <>
          <hr />
          <h2>{t('PaymentProcessing_Delayed1')}</h2>
          <p>{t('PaymentProcessing_Delayed2', { partner: getPartnerName() })}</p>
        </>
      )}
    </IconTextContainer>
  );
}

export default translate(PaymentProcessing);
